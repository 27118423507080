var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("read", "clients")
    ? _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-between flex-column",
                  attrs: { cols: "21", xl: "4", lg: "4" }
                },
                [
                  _c("div", { staticClass: "d-flex justify-content-start" }, [
                    _c("div", { staticClass: "d-flex flex-column ml-1" }, [
                      _c("div", { staticClass: "mb-1" }, [
                        _c("h4", { staticClass: "mb-0" }, [
                          _vm._v(
                            " " + _vm._s(_vm.clientSiteData.sitename) + " "
                          )
                        ])
                      ]),
                      _vm.$can("update", "clients")
                        ? _c(
                            "div",
                            { staticClass: "d-flex flex-wrap" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    to: {
                                      name: "apps-client-sites-edit",
                                      params: {
                                        clientname:
                                          _vm.clientSiteData.clientname,
                                        sitename: _vm.clientSiteData.sitename,
                                        clientSiteData: _vm.clientSiteData
                                      }
                                    },
                                    variant: "primary"
                                  }
                                },
                                [_vm._v(" Edit ")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ])
                ]
              ),
              _c("b-col", { attrs: { cols: "12", xl: "4", lg: "4" } }, [
                _c("table", { staticClass: "mt-2 mt-xl-0 w-100" }, [
                  _c("tr", [
                    _c(
                      "th",
                      { staticClass: "pb-50" },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-75",
                          attrs: { icon: "MapIcon" }
                        }),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Site Name")
                        ])
                      ],
                      1
                    ),
                    _c("td", { staticClass: "pb-50" }, [
                      _vm._v(" " + _vm._s(_vm.clientSiteData.sitename) + " ")
                    ])
                  ]),
                  _c("tr", [
                    _c(
                      "th",
                      { staticClass: "pb-50" },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-75",
                          attrs: { icon: "UserIcon" }
                        }),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Client")
                        ])
                      ],
                      1
                    ),
                    _c("td", { staticClass: "pb-50 text-capitalize" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.clientSiteData.client_display_name) +
                          " "
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c(
                      "th",
                      { staticClass: "pb-50" },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-75",
                          attrs: { icon: "PhoneIcon" }
                        }),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Phone")
                        ])
                      ],
                      1
                    ),
                    _c("td", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatPhoneNumber(_vm.clientSiteData.phone)
                          ) +
                          " "
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c(
                      "th",
                      { staticClass: "pb-50" },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-75",
                          attrs: { icon: "CheckIcon" }
                        }),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Status")
                        ])
                      ],
                      1
                    ),
                    _c("td", { staticClass: "pb-50 text-capitalize" }, [
                      _vm._v(" " + _vm._s(_vm.clientSiteData.status) + " ")
                    ])
                  ]),
                  _c("tr", [_c("span", [_c("br")])]),
                  _c("tr", [
                    _c(
                      "th",
                      { staticClass: "pb-50" },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-75",
                          attrs: { icon: "MapPinIcon" }
                        }),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Address")
                        ])
                      ],
                      1
                    ),
                    _vm._v(" " + _vm._s(_vm.clientSiteData.address) + " ")
                  ]),
                  _vm.clientSiteData.addressUnit &&
                  _vm.clientSiteData.addressUnit !== ""
                    ? _c("tr", [
                        _c("th", { staticClass: "pb-50" }),
                        _c("td", { staticClass: "pb-50 text-capitalize" }, [
                          _vm._v(
                            " " + _vm._s(_vm.clientSiteData.addressUnit) + " "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c("tr", [
                    _c("th", { staticClass: "pb-50" }),
                    _c("td", { staticClass: "pb-50 text-capitalize" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.clientSiteData.city) +
                          ", " +
                          _vm._s(_vm.clientSiteData.province) +
                          " "
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c("th", { staticClass: "pb-50" }),
                    _c("td", { staticClass: "pb-50 text-capitalize" }, [
                      _vm._v(" " + _vm._s(_vm.clientSiteData.country) + " ")
                    ])
                  ]),
                  _c("tr", [
                    _c("th", { staticClass: "pb-50" }),
                    _c("td", { staticClass: "pb-50 text-capitalize" }, [
                      _vm._v(" " + _vm._s(_vm.clientSiteData.postal) + " ")
                    ])
                  ]),
                  _c("tr", [_c("span", [_c("br")])]),
                  _c("tr", [
                    _c(
                      "th",
                      { staticClass: "pb-50 align-top" },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-75",
                          attrs: { icon: "BriefcaseIcon" }
                        }),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Services")
                        ])
                      ],
                      1
                    ),
                    _c("td", { staticClass: "pb-50 text-capitalize" }, [
                      _c(
                        "ul",
                        { staticClass: "ml-0 pl-1" },
                        _vm._l(_vm.servicesList, function(item) {
                          return _c("li", { key: item.id }, [
                            _vm._v(" " + _vm._s(item.value) + " ")
                          ])
                        }),
                        0
                      )
                    ])
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }