var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("read", "clients")
    ? _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c("div", [
                  _c("div", [
                    _c("div", { staticClass: "mb-1" }, [
                      _c("h4", { staticClass: "mb-0" }, [_vm._v(" Notes ")])
                    ]),
                    _vm.loggedInUserData.group === "Management"
                      ? _c(
                          "div",
                          [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Admin Notes")
                            ]),
                            _c("b-form-textarea", {
                              staticClass: "mt-1 mb-2",
                              attrs: {
                                id: "adminnote",
                                rows: "3",
                                "max-rows": "6",
                                readonly: ""
                              },
                              model: {
                                value: _vm.clientSiteData.notes_admin,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.clientSiteData,
                                    "notes_admin",
                                    $$v
                                  )
                                },
                                expression: "clientSiteData.notes_admin"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.loggedInUserData.group === "Management" ||
                    _vm.loggedInUserData.group === "Client_Admin"
                      ? _c(
                          "div",
                          [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Client Notes")
                            ]),
                            _c("b-form-textarea", {
                              staticClass: "mt-1 mb-2",
                              attrs: {
                                id: "adminnote",
                                rows: "3",
                                "max-rows": "6",
                                readonly: ""
                              },
                              model: {
                                value: _vm.clientSiteData.notes_client,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.clientSiteData,
                                    "notes_client",
                                    $$v
                                  )
                                },
                                expression: "clientSiteData.notes_client"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Public Notes")
                        ]),
                        _c("b-form-textarea", {
                          staticClass: "mt-1 mb-2",
                          attrs: {
                            id: "adminnote",
                            rows: "3",
                            "max-rows": "6",
                            readonly: ""
                          },
                          model: {
                            value: _vm.clientSiteData.notes_public,
                            callback: function($$v) {
                              _vm.$set(_vm.clientSiteData, "notes_public", $$v)
                            },
                            expression: "clientSiteData.notes_public"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }